import { h } from "preact";
import style from "./style.scss";
import ZennichiContactFormIKS from "../../../components/ZennichiContactFormIKS";
import useAnchorHandle from "../../../util/useAnchorHandle";

function InquiryButton(props) {
  return (
    <a
      native
      className={style.InquiryButtonContainer}
      target={(props.target === "_blank" && "_blank") || ""}
      rel={(props.target === "_blank" && "noopener noreferrer") || ""}
      href="#contact"
    >
      <p className={style.InquiryButtonText}>お申込み・お問い合わせ</p>
    </a>
  );
}

function PrimaryButton(props) {
  return (
    <a native className={style.PrimaryButtonContainer} href={props.href}>
      <img className={style.PrimaryButtonIcon} src={props.iconSrc} />
      <p className={style.PrimaryButtonText}>{props.text}</p>
    </a>
  );
}

function SecondaryButton(props) {
  return (
    <a
      native
      className={style.SecondaryButtonContainer}
      href={props.href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img className={style.SecondaryButtonIcon} src={props.iconSrc} />
      <p className={style.SecondaryButtonText}>{props.text}</p>
    </a>
  );
}

function CTAAreaIKS() {
  return (
    <section className={style.CTAAreaIKSSection}>
      <div className={style.CTAAreaContainer}>
        <p className={style.CTAAreaHeading}>
          一括出稿システムを
          <br />
          <span className={style.CTAAreaHeadingOrangeText}>無料で</span>
          始めませんか？
        </p>
        <div className={style.CTAAreaButtonContainer}>
          <PrimaryButton
            iconSrc="/assets/denshi_keiyaku/icon/mail_white.svg"
            text="資料請求・お問い合わせ"
            href="#contact"
          />
        </div>
      </div>
    </section>
  );
}

const ZennichiIkkatsuSyukko = () => {
  useAnchorHandle();
  return (
    <>
      <section className={style.FirstViewSection}>
        <ol className={style.BreadcrumbsArea}>
          <li className={style.Breadcrumbs}>
            <a className={style.BreadcrumbsItem} href="/zennichi/itandi-bb">
              TOP
            </a>
          </li>
          <li className={style.Breadcrumbs}>
            <a className={style.BreadcrumbsItem} href="">
              一括出稿システム
            </a>
          </li>
        </ol>
        <div className={style.FirstViewContainer}>
          <div className={style.FirstViewInnerWrapper}>
            <div className={style.FirstViewLogoArea}>
              <div className={style.FirstViewLogoAreaBg01}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M30 30L30 0L0 -1.31134e-06C16.5685 -5.87108e-07 30 13.4315 30 30Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className={style.FirstViewLogoAreaBg02}>
                <img
                  className={style.FirstViewLogoImage}
                  src="/assets/service_logo/itandibbplus_IKS.svg"
                  alt="ラビネット 一括出稿システム"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="167"
                  height="45"
                  viewBox="0 0 167 45"
                  fill="none"
                >
                  <path
                    d="M0 0H167.31V24.35C167.31 35.3957 158.356 44.35 147.31 44.35H20C8.9543 44.35 0 35.3957 0 24.35V0Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className={style.FirstViewLogoAreaBg03}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.31134e-06 30L0 0L30 -1.31134e-06C13.4315 -5.87108e-07 5.87108e-07 13.4315 1.31134e-06 30Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            <div className={style.FirstViewTextWrapper}>
              <h1 className={style.FirstViewCatchCopy}>
                <span className={style.FirstViewCatchCopyText}>
                  物件情報を一括出稿
                </span>
                <br />
                <span className={style.FirstViewCatchCopyText}>
                  物出し業務負担を軽減
                </span>
              </h1>
              <div className={style.FirstViewCatchCopySub}>
                無料・業界最多の
                <br className={style.FirstViewCatchCopySubBrSp} />
                高頻度連携
              </div>
              <InquiryButton />
            </div>
            <div className={style.FirstViewVisualContainer}>
              <img
                className={style.FirstViewVisualImage}
                src="/assets/zennichi/ikkatsu-syukko/IKSMockup.png"
                alt="ラビーネット一括出稿システム画面イメージ"
              />
            </div>
          </div>
          <div className={style.BgCircle} />
        </div>
      </section>
      <section className={style.WorriesSection}>
        <div className={style.WorriesWrapper}>
          <h3 className={style.WorriesTitle}>
            <span className={style.TitleBr}>
              <span>一括出稿サービスは</span>
              <span>悩ましい</span>
            </span>
          </h3>
          <div className={style.WorriesImage}>
            <img
              className={style.WorriesImageItemPC}
              src="/assets/zennichi/ikkatsu-syukko/WorriesSectionIllustrationPC.svg"
              alt="物件情報の出稿頻度が少ない・利用料が高い・物確電話の手間"
            />
            <img
              className={style.WorriesImageItemSP}
              src="/assets/zennichi/ikkatsu-syukko/WorriesSectionIllustrationSP.svg"
              alt="物件情報の出稿頻度が少ない・利用料が高い・物確電話の手間"
            />
          </div>
        </div>
      </section>
      <section className={style.FeatureSection}>
        <div className={style.FeatureWrapper}>
          <h3 className={style.FeatureTitle}>
            <span className={style.TitleBrTab}>
              <span>ラビーネット</span>
              <span>一括出稿サービスなら</span>
              <span>すべて解決</span>
            </span>
          </h3>
          <p className={style.FeatureDescription}>
            3つの特徴で一括出稿システムにまつわるお悩みを解決します。
          </p>
          <div className={style.FeatureListContainer}>
            <div className={style.FeatureList}>
              <div className={style.FeatureCardContainer}>
                <div className={style.FeatureCardDescription}>
                  <p className={style.FeatureCardNumber}>POINT 01</p>
                  <h3 className={style.FeatureCardHeadingText}>
                    業界最多の
                    <br />
                    出稿頻度
                  </h3>
                </div>
                <img
                  className={style.FeatureCardIllust}
                  src="/assets/zennichi/ikkatsu-syukko/POINT01_Illustration.svg"
                  alt=""
                />
              </div>
              <div className={style.FeatureCardContainer}>
                <div className={style.FeatureCardDescription}>
                  <p className={style.FeatureCardNumber}>POINT 02</p>
                  <h3 className={style.FeatureCardHeadingText}>
                    基本料金が
                    <br />
                    無料
                    <small className={style.FeatureCardHeadingTextSmall}>
                      ※
                    </small>
                  </h3>
                </div>
                <img
                  className={style.FeatureCardIllust}
                  src="/assets/zennichi/ikkatsu-syukko/POINT02_Illustration.svg"
                  alt=""
                />
              </div>
              <div className={style.FeatureCardContainer}>
                <div className={style.FeatureCardDescription}>
                  <p className={style.FeatureCardNumber}>POINT 03</p>
                  <h3 className={style.FeatureCardHeadingText}>
                    物件確認
                    <br />
                    電話が不要に
                  </h3>
                </div>
                <img
                  className={style.FeatureCardIllust}
                  src="/assets/zennichi/ikkatsu-syukko/POINT03_Illustration.svg"
                  alt=""
                />
              </div>
            </div>
            <div className={style.FeatureCardNote}>
              ※件数制限有り。各サイト加入料・掲載料は別途必要。
            </div>
          </div>
          <div className={style.FeatureFlow}>
            <div className={style.FeatureFlowRabbyIKS}>
              <div>
                <img
                  className={style.FeatureFlowRabbyIKSImage}
                  src="/assets/zennichi/ikkatsu-syukko/RabbyIKS_mock_laptop202307.png"
                />
              </div>
              <div>
                <img
                  className={style.FeatureFlowRabbyIKSLogo}
                  src="/assets/zennichi/ikkatsu-syukko/RabbyIKS_Logo_Black.svg"
                  alt="ラビーネット一括出稿システム"
                />
              </div>
            </div>
            <img
              className={style.FeatureFlowImagePC}
              src="/assets/zennichi/ikkatsu-syukko/FlowImagePC_20230621.png"
            />
            <img
              className={style.FeatureFlowImageSP}
              src="/assets/zennichi/ikkatsu-syukko/FlowImageSP_20230621.png"
            />
          </div>
          <div className={style.FeaturePoint}>
            <div className={style.FeaturePointWrapper}>
              <div className={style.FeaturePointDescription}>
                <div className={style.FeaturePointNumber}>01</div>
                <h4 className={style.FeaturePointHeadingText}>
                  <span className={style.FeaturePointHeadingTextStrong}>
                    業界最多の出稿頻度
                  </span>
                  で
                  <br />
                  情報更新の手間をゼロに
                </h4>
                <p className={style.FeaturePointText}>
                  レインズへの出稿頻度が業界最多の
                  <strong>最大13回/1日。</strong>
                  <br />
                  成約情報・物件情報が最新に保たれるから、
                  <br />
                  手入力での情報更新の手間がなくなります。
                </p>
              </div>
              <div className={style.FeaturePointIllust}>
                <img
                  className={style.FeaturePointIllustItem}
                  src="/assets/zennichi/ikkatsu-syukko/01Illustration.svg"
                  alt=""
                />
              </div>
            </div>
            <div className={style.FeaturePointWrapper}>
              <div className={style.FeaturePointDescription}>
                <div className={style.FeaturePointNumber}>02</div>
                <h4 className={style.FeaturePointHeadingText}>
                  <span className={style.FeaturePointHeadingTextStrong}>
                    無料
                  </span>
                  で使えるから
                  <br />
                  コストを大幅に削減
                </h4>
                <p className={style.FeaturePointText}>
                  指定流通機構・業者間サイト・消費者向けサイトへの連動料金が無料で行えます。
                  オプションで制限上限枠を増やすことも可能。貴社に合ったプランにカスタマイズできます。
                </p>
                <small className={style.FeatureCardNote}>
                  ※各サイトの加入料と掲載料金は別途必要です。
                  <br />
                  ※各サイトの料金プランにより掲載数制限がございます。
                </small>
              </div>
              <div className={style.FeaturePointIllust}>
                <img
                  className={style.FeaturePointIllustItem}
                  src="/assets/zennichi/ikkatsu-syukko/02Illustration_202308.svg"
                  alt=""
                />
              </div>
            </div>
            <div className={style.FeaturePointWrapper}>
              <div className={style.FeaturePointDescription}>
                <div className={style.FeaturePointNumber}>03</div>
                <h4 className={style.FeaturePointHeadingText}>
                  <span className={style.FeaturePointHeadingTextStrong}>
                    物件確認電話が不要
                  </span>
                  になり、
                  <br />
                  物出し業務の負担を軽減
                </h4>
                <p className={style.FeaturePointText}>
                  リアルタイム業者間サイト「ITANDI
                  BB」と自動連携しているから、物件状況がひと目で分かります。物出し業務における物確電話が不要になり、業務効率化に繋がります。
                </p>
              </div>
              <div className={style.FeaturePointIllust}>
                <img
                  className={style.FeaturePointIllustItem}
                  src="/assets/zennichi/ikkatsu-syukko/03Illustration_202308.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <CTAAreaIKS />

      <section className={style.PortalSection} id="PortalSection">
        <div className={style.PortalWrapper}>
          <h3 className={style.FeatureTitle}>
            <span className={style.TitleBr}>
              <span>出稿可能な</span>
              <span>ポータルサイト一覧</span>
            </span>
          </h3>
          <p className={style.FeatureDescription}>
            各サイト加入料・掲載料が必要となります。また、料金プランにより件数制限がございます。
          </p>
          <table className={style.PortalTable}>
            <tr className={style.PortalTableTrTitle}>
              <th className={style.PortalTableTh}>サイト名</th>
              <th className={style.PortalTableTh}>賃貸</th>
              <th className={style.PortalTableTh}>売買</th>
              <th className={style.PortalTableTh}>業者間流通</th>
              <th className={style.PortalTableTh}>課金方法</th>
              <th className={style.PortalTableTh}>料金</th>
            </tr>
            <tr className={style.PortalTableTr}>
              <td className={style.PortalTableTd}>
                <img
                  className={style.PortalLogoImage}
                  src="/assets/zennichi/ikkatsu-syukko/suumo_Logo.png"
                  alt="suumo"
                />
              </td>
              <td className={style.PortalTableTd}>○</td>
              <td className={style.PortalTableTd}>○</td>
              <td className={style.PortalTableTd}>賃貸のみ</td>
              <td className={style.PortalTableTd}>掲載課金</td>
              <td className={style.PortalTableTd}>
                <div className={style.PortalTableTdWrapper}>
                  <ol className={style.PortalTableTdDescription}>
                    <li className={style.PortalTableTdTitle}>
                      対象カテゴリー
                      <ol className={style.PortalTableTdList}>
                        <li className={style.PortalTableTdListItems}>
                          SUUMO賃貸
                        </li>
                        <small className={style.PortalTableTdSmall}>
                          ※SUUMO売買はキャンペーンを実施しておりません。
                        </small>
                      </ol>
                    </li>
                    <li className={style.PortalTableTdTitle}>
                      キャンペーン対象
                      <ol className={style.PortalTableTdList}>
                        <li className={style.PortalTableTdListItems}>
                          SUUMO賃貸に過去1年間掲載をしていない事業者
                        </li>
                      </ol>
                    </li>
                    <li className={style.PortalTableTdTitle}>
                      キャンペーン内容
                      <ol className={style.PortalTableTdList}>
                        <li
                          className={`${style.PortalTableTdListItems} ${style.PortalTableTdListItemsStrong}`}
                        >
                          初期費用：0円
                          <small className={style.PortalTableTdSmall}>
                            （通常：20,000円）
                          </small>
                        </li>
                        <ol className={style.PortalTableTdList}>
                          <li className={style.PortalTableTdListItems}>
                            5枠：1,250円/月
                            <small className={style.PortalTableTdSmall}>
                              （50枠以下に限り単価250円/月 5枠単位での契約）
                            </small>
                          </li>
                        </ol>
                      </ol>
                    </li>
                    <li className={style.PortalTableStrong}>
                      キャンペーンの申込受付は2025年3月末まで
                    </li>
                  </ol>
                  <SecondaryButton
                    iconSrc="/assets/zennichi/ikkatsu-syukko/external-link-line.svg"
                    text="詳細ページへ"
                    href="https://www.zennichi.or.jp/mypage/suumo-alliance/"
                  />
                </div>
              </td>
            </tr>
            <tr className={style.PortalTableTr}>
              <td className={style.PortalTableTd}>
                <img
                  className={style.PortalLogoImage}
                  src="/assets/zennichi/ikkatsu-syukko/athome_Logo.png"
                  alt="at home"
                />
              </td>
              <td className={style.PortalTableTd}>○</td>
              <td className={style.PortalTableTd}>○</td>
              <td className={style.PortalTableTd}>○</td>
              <td className={style.PortalTableTd}>掲載課金</td>
              <td className={style.PortalTableTd}>
                <div className={style.PortalTableTdWrapper}>
                  <SecondaryButton
                    iconSrc="/assets/zennichi/ikkatsu-syukko/external-link-line.svg"
                    text="詳細ページへ"
                    href="https://www.zennichi.or.jp/mypage/athome-alliance/"
                  />
                </div>
              </td>
            </tr>
            <tr className={style.PortalTableTr}>
              <td className={style.PortalTableTd}>
                <img
                  className={style.PortalLogoImage}
                  src="/assets/zennichi/ikkatsu-syukko/LIFULLHOMES_Logo.png"
                  alt="LIFULLHOME'S"
                />
              </td>
              <td className={style.PortalTableTd}>○</td>
              <td className={style.PortalTableTd}>○</td>
              <td className={style.PortalTableTd}>賃貸のみ</td>
              <td className={style.PortalTableTd}>掲載課金・反響課金</td>
              <td className={style.PortalTableTd}>
                <div className={style.PortalTableTdWrapper}>
                  <SecondaryButton
                    iconSrc="/assets/zennichi/ikkatsu-syukko/external-link-line.svg"
                    text="詳細ページへ"
                    href="https://www.zennichi.or.jp/mypage/homes-alliance/"
                  />
                </div>
              </td>
            </tr>
            <tr className={style.PortalTableTr}>
              <td className={style.PortalTableTd}>ホームアドパーク</td>
              <td className={style.PortalTableTd}>○</td>
              <td
                className={`${style.PortalTableTd} ${style.PortalTableTdSmallCenter}`}
              >
                2025年
                <br />
                連動開始予定
              </td>
              <td className={style.PortalTableTd}>-</td>
              <td className={style.PortalTableTd}>
                掲載課金
                <div className={style.PortalTableTdSmallCenter}>
                  ※月額定額制
                </div>
              </td>
              <td className={style.PortalTableTd}>
                <div className={style.PortalTableTdWrapper}>
                  <ol className={style.PortalTableTdDescription}>
                    <li className={style.PortalTableTdTitle}>
                      対象カテゴリー
                      <ol className={style.PortalTableTdList}>
                        <li className={style.PortalTableTdListItems}>
                          消費者向けサイト「賃貸（居住用）」
                        </li>
                        <small>※売買のキャンペーンは準備中です。</small>
                      </ol>
                    </li>
                    <li className={style.PortalTableTdTitle}>
                      キャンペーン対象
                      <ol className={style.PortalTableTdList}>
                        <li className={style.PortalTableTdListItems}>
                          新規申込みの場合のみ対象です。
                        </li>
                      </ol>
                    </li>
                    <li className={style.PortalTableTdTitle}>
                      キャンペーン内容
                      <ol className={style.PortalTableTdList}>
                        <li
                          className={`${style.PortalTableTdListItems} ${style.PortalTableTdListItemsStrong}`}
                        >
                          月額費用：0円
                          <small className={style.PortalTableTdSmall}>
                            （掲載件数20件まで）
                          </small>
                        </li>
                        <li
                          className={`${style.PortalTableTdListItems} ${style.PortalTableTdListItemsStrong}`}
                        >
                          入会金：0円
                          <small className={style.PortalTableTdSmall}>
                            （通常：30,000円）
                          </small>
                        </li>
                        <li
                          className={`${style.PortalTableTdListItems} ${style.PortalTableTdListItemsStrong}`}
                        >
                          サーバー連動費：0円
                          <small className={style.PortalTableTdSmall}>
                            （通常：15,000円）
                          </small>
                        </li>
                      </ol>
                    </li>
                    <div className={style.PortalTableTdSmall}>
                      ※初回のみ事務手数料20,000円が必要です。
                    </div>
                  </ol>
                  <SecondaryButton
                    iconSrc="/assets/zennichi/ikkatsu-syukko/external-link-line.svg"
                    text="詳細ページへ"
                    href="https://home.adpark.co.jp/service/rabby/keisai/"
                  />
                </div>
              </td>
            </tr>
            <tr className={style.PortalTableTr}>
              <td className={style.PortalTableTd}>スモッカ</td>
              <td className={style.PortalTableTd}>○</td>
              <td className={style.PortalTableTd}>-</td>
              <td className={style.PortalTableTd}>-</td>
              <td className={style.PortalTableTd}>
                掲載課金・反響課金
                <div className={style.PortalTableTdSmallCenter}>
                  ※一部エリアは対象外
                </div>
              </td>
              <td className={style.PortalTableTd}>
                <div className={style.PortalTableTdWrapper}>
                  <ol className={style.PortalTableTdDescription}>
                    <li className={style.PortalTableTdTitle}>
                      対象カテゴリー
                      <ol className={style.PortalTableTdList}>
                        <li className={style.PortalTableTdListItems}>
                          消費者向けサイト
                        </li>
                      </ol>
                    </li>
                    <li className={style.PortalTableTdTitle}>
                      キャンペーン内容
                      <ol className={style.PortalTableTdList}>
                        <li
                          className={`${style.PortalTableTdListItems} ${style.PortalTableTdListItemsStrong}`}
                        >
                          初期費用：0円
                          <small className={style.PortalTableTdSmall}>
                            （通常20,000円）
                          </small>
                        </li>
                        <li
                          className={`${style.PortalTableTdListItems} ${style.PortalTableTdListItemsStrong}`}
                        >
                          月額：3ヶ月間0円
                          <small className={style.PortalTableTdSmall}>
                            （通常10,000円）
                          </small>
                        </li>
                        <li
                          className={`${style.PortalTableTdListItems} ${style.PortalTableTdListItemsStrong}`}
                        >
                          反響単価：1件3,500円
                          <small className={style.PortalTableTdSmall}>
                            （通常1件4,000円）
                          </small>
                        </li>
                        <li
                          className={`${style.PortalTableTdListItems} ${style.PortalTableTdListItemsStrong}`}
                        >
                          オプション割引：スマート電話（自動追客機能）1件500円
                          <small className={style.PortalTableTdSmall}>
                            （通常1件2,000円）
                          </small>
                        </li>
                      </ol>
                    </li>
                    <ul className={style.PortalTableTdListNote}>
                      ※お問い合わせ時に、以下2点をお伝えください。
                      <li className={style.PortalTableTdListNoteItems}>
                        全日本不動産協会の会員であること
                      </li>
                      <li className={style.PortalTableTdListNoteItems}>
                        ラビーネットBB一括出稿システムを利用すること
                      </li>
                    </ul>
                  </ol>
                  <SecondaryButton
                    iconSrc="/assets/zennichi/ikkatsu-syukko/external-link-line.svg"
                    text="詳細ページへ"
                    href="https://smocca.jp/statics/business"
                  />
                </div>
              </td>
            </tr>
            <tr className={style.PortalTableTr}>
              <td className={style.PortalTableTd}>スマイティ</td>
              <td className={style.PortalTableTd}>○</td>
              <td className={style.PortalTableTd}>-</td>
              <td className={style.PortalTableTd}>-</td>
              <td className={style.PortalTableTd}>反響課金</td>
              <td className={style.PortalTableTd}>
                <div className={style.PortalTableTdWrapper}>
                  <ol className={style.PortalTableTdDescription}>
                    <li className={style.PortalTableTdTitle}>
                      対象カテゴリー
                      <ol className={style.PortalTableTdList}>
                        <li className={style.PortalTableTdListItems}>
                          消費者向けサイト
                        </li>
                      </ol>
                    </li>
                    <li className={style.PortalTableTdTitle}>
                      キャンペーン内容
                      <ol className={style.PortalTableTdList}>
                        <li
                          className={`${style.PortalTableTdListItems} ${style.PortalTableTdListItemsStrong}`}
                        >
                          PR枠に物件を掲載
                          <small className={style.PortalTableTdSmall}>
                            （3か月間、サイトの上位に表示されます）
                          </small>
                        </li>
                      </ol>
                    </li>
                  </ol>
                  <SecondaryButton
                    iconSrc="/assets/zennichi/ikkatsu-syukko/external-link-line.svg"
                    text="詳細ページへ"
                    href="https://sumaity.com/business/chintai/zennichi/"
                  />
                </div>
              </td>
            </tr>
            <tr className={style.PortalTableTr}>
              <td className={style.PortalTableTd}>DOOR</td>
              <td className={style.PortalTableTd}>○</td>
              <td className={style.PortalTableTd}>-</td>
              <td className={style.PortalTableTd}>-</td>
              <td className={style.PortalTableTd}>反響課金</td>
              <td className={style.PortalTableTd}>
                <div className={style.PortalTableTdWrapper}>
                  <ol className={style.PortalTableTdDescription}>
                    <li className={style.PortalTableTdTitle}>
                      対象カテゴリー
                      <ol className={style.PortalTableTdList}>
                        <li className={style.PortalTableTdListItems}>
                          消費者向けサイト
                        </li>
                      </ol>
                    </li>
                    <li className={style.PortalTableTdTitle}>
                      キャンペーン内容
                      <ol className={style.PortalTableTdList}>
                        <li
                          className={`${style.PortalTableTdListItems} ${style.PortalTableTdListItemsStrong}`}
                        >
                          反響費用：1件4,000円
                          <small className={style.PortalTableTdSmall}>
                            （通常：1件5,000円）
                          </small>
                        </li>
                        <small className={style.PortalTableTdSmall}>
                          ※物件の露出が多いプレミアムプランが、ライトプランと同料金で、ご利用いただけます。
                        </small>
                      </ol>
                    </li>
                    <ul className={style.PortalTableTdListNote}>
                      ※お問い合わせ時に、以下2点をお伝えください。
                      <li className={style.PortalTableTdListNoteItems}>
                        全日本不動産協会の会員であること
                      </li>
                      <li className={style.PortalTableTdListNoteItems}>
                        ラビーネットBB一括出稿システムを利用すること
                      </li>
                    </ul>
                  </ol>

                  <SecondaryButton
                    iconSrc="/assets/zennichi/ikkatsu-syukko/external-link-line.svg"
                    text="詳細ページへ"
                    href="https://door.ac/info/corp?cnv=rabbynet"
                  />
                </div>
              </td>
            </tr>
            <tr className={style.PortalTableTr}>
              <td className={style.PortalTableTd}>
                <a
                  href="https://www.rakumachi.jp"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className={style.PortalLogoImage}
                    src="/assets/zennichi/ikkatsu-syukko/RakumachiLogo.png"
                    alt="収益物件数No.1 国内最大の不動産投資サイト楽待（らくまち）"
                  />
                </a>
              </td>
              <td className={style.PortalTableTd}>-</td>
              <td className={style.PortalTableTd}>○</td>
              <td className={style.PortalTableTd}>-</td>
              <td className={style.PortalTableTd}>掲載課金</td>
              <td className={style.PortalTableTd}>
                <div className={style.PortalTableTdWrapper}>
                  <ol className={style.PortalTableTdDescription}>
                    <li className={style.PortalTableTdTitle}>
                      対象カテゴリー
                      <ol className={style.PortalTableTdList}>
                        <li className={style.PortalTableTdListItems}>
                          消費者向けサイト
                        </li>
                      </ol>
                    </li>
                    <li className={style.PortalTableTdTitle}>
                      キャンペーン内容
                      <ol className={style.PortalTableTdList}>
                        <li
                          className={`${style.PortalTableTdListItems} ${style.PortalTableTdListItemsStrong}`}
                        >
                          【ラビーネット経由限定！】初期費用50,000円+初月掲載料無料キャンペーン中
                        </li>
                        <small className={style.PortalTableTdSmall}>
                          ※条件あり
                        </small>
                      </ol>
                    </li>
                  </ol>
                  <SecondaryButton
                    iconSrc="/assets/zennichi/ikkatsu-syukko/external-link-line.svg"
                    text="詳細ページへ"
                    href="https://www.rakumachi.jp/r/qom5bjbi"
                  />
                </div>
              </td>
            </tr>
            <tr className={style.PortalTableTr}>
              <td className={style.PortalTableTd}>不動産連合隊</td>
              <td className={style.PortalTableTd}>○</td>
              <td
                className={`${style.PortalTableTd} ${style.PortalTableTdSmallCenter}`}
              >
                2025年
                <br />
                連動開始予定
              </td>
              <td className={style.PortalTableTd}>-</td>
              <td className={style.PortalTableTd}>掲載課金</td>
              <td className={style.PortalTableTd}>
                <div className={style.PortalTableTdWrapper}>
                  <ol className={style.PortalTableTdDescription}>
                    <li className={style.PortalTableTdTitle}>
                      対象カテゴリー
                      <ol className={style.PortalTableTdList}>
                        <li className={style.PortalTableTdListItems}>
                          消費者向けサイト
                        </li>
                      </ol>
                    </li>
                    <li className={style.PortalTableTdTitle}>
                      キャンペーン対象
                      <ol className={style.PortalTableTdList}>
                        <li className={style.PortalTableTdListItems}>
                          不動産連合隊未加盟の全日本不動産協会会員の方
                        </li>
                        <li className={style.PortalTableTdListItems}>
                          新規申込みの場合のみ対象です。
                        </li>
                      </ol>
                    </li>
                    <li className={style.PortalTableTdTitle}>
                      掲載可能エリア
                      <ol className={style.PortalTableTdList}>
                        <li className={style.PortalTableTdListItems}>
                          【居住用賃貸・居住用売買・貸テナント】
                        </li>
                        <ol className={style.PortalTableTdList}>
                          <li className={style.PortalTableTdListItems}>
                            北海道全域
                          </li>
                          <li className={style.PortalTableTdListItems}>
                            仙台市近郊
                          </li>
                          <li className={style.PortalTableTdListItems}>
                            浜松市近郊
                          </li>
                          <li className={style.PortalTableTdListItems}>
                            福岡市近郊（北九州市含む）
                          </li>
                          <li className={style.PortalTableTdListItems}>
                            佐世保市近郊
                          </li>
                        </ol>
                        <li className={style.PortalTableTdListItems}>
                          【投資物件】
                        </li>
                        <ol className={style.PortalTableTdList}>
                          <li className={style.PortalTableTdListItems}>
                            全国の物件を掲載可
                          </li>
                        </ol>
                      </ol>
                    </li>
                    <li className={style.PortalTableTdTitle}>
                      キャンペーン内容
                      <ol className={style.PortalTableTdList}>
                        <li
                          className={`${style.PortalTableTdListItems} ${style.PortalTableTdListItemsStrong}`}
                        >
                          初期費用：0円
                          <small className={style.PortalTableTdSmall}>
                            （通常：50,000円）
                          </small>
                        </li>
                        <li
                          className={`${style.PortalTableTdListItems} ${style.PortalTableTdListItemsStrong}`}
                        >
                          掲載費用：半額
                          <small className={style.PortalTableTdSmall}>
                            （3ヶ月間）
                          </small>
                        </li>
                      </ol>
                    </li>
                    <div className={style.PortalTableTdSmallStrong}>
                      ※賃貸物件及び賃貸事業用物件のみラビーネットBBから連携可能です。他業態は手入力での掲載となります。
                    </div>
                    <ul className={style.PortalTableTdListNote}>
                      ※お問い合わせ時に、以下2点をお伝えください。
                      <li className={style.PortalTableTdListNoteItems}>
                        全日本不動産協会の会員であること
                      </li>
                      <li className={style.PortalTableTdListNoteItems}>
                        ラビーネットBB一括出稿システムを利用すること
                      </li>
                    </ul>
                    <li className={style.PortalTableStrong}>
                      売買物件は、2025年中に連動開始予定！
                    </li>
                  </ol>
                  <SecondaryButton
                    iconSrc="/assets/zennichi/ikkatsu-syukko/external-link-line.svg"
                    text="詳細ページへ"
                    href="https://www.rals.co.jp/rengotai/zennichi.php"
                  />
                </div>
              </td>
            </tr>
          </table>
          <div className={style.PortalImageNote}>
            ※上記のキャンペーンは2024年12月時点のものとなります。詳細は下記フォームより各社にお問い合わせください。
          </div>
        </div>
      </section>

      <CTAAreaIKS />

      <section className={style.ChargeSection} id="ChargeSection">
        <div className={style.ChargeWrapper}>
          <h3 className={style.FeatureTitle}>
            <span className={style.TitleBr}>
              <span>一括出稿システム</span>
              <span>料金表</span>
            </span>
          </h3>
          <p className={style.FeatureDescription}>
            気軽に使える無料プランと無制限プランの2種をご用意。
            <br />
            オプションで上限枠を追加することも可能です。詳細はお問い合わせください。
          </p>
          <div className={style.ImageContainer}>
            <img
              className={`${style.ChargeImage} ${style.ChargeImagePC}`}
              src="/assets/zennichi/ikkatsu-syukko/IKScharge.svg"
              alt="一括出稿システム料金表"
            />
            <img
              className={`${style.ChargeImage} ${style.ChargeImageSP}`}
              src="/assets/zennichi/ikkatsu-syukko/IKSchargeFreePlan.png"
              alt="一括出稿システム料金表（無料プラン）"
            />
            <img
              className={`${style.ChargeImage} ${style.ChargeImageSP}`}
              src="/assets/zennichi/ikkatsu-syukko/IKSchargeUltimatePlan.png"
              alt="一括出稿システム料金表（無制限プラン）"
            />
          </div>
          <div className={style.PortalImageNote}>
            ※一括出稿システム利用料に加え、各種ポータルサイトの掲載料が別途かかります。料金詳細は下記のフォームより各社へお問い合わせください。
          </div>
          <div className={style.ChargeImageNote}>
            ※実際のご請求額は、消費税を加算したものとなります。
          </div>
        </div>
      </section>

      <CTAAreaIKS />

      <section className={style.FlowSection}>
        <div className={style.FlowWrapper}>
          <h3 className={style.WorriesTitle}>ご利用までの流れ</h3>
          <ol className={style.FlowList}>
            <li className={style.FlowListItem}>
              <div className={style.FlowListBox}>
                <div
                  className={`${style.FlowListBoxImage} ${style.FlowListBoxImage01}`}
                />
                <div className={style.FlowListBoxText}>
                  <h4 className={style.FlowListBoxTextTitle}>
                    <span className={style.TitleBrPC}>フォームから</span>
                    <span className={style.TitleBrPC}>お申込み</span>
                  </h4>
                  <p className={style.FlowListBoxTextDescription}>
                    下記フォームにご希望の出稿先にチェックを入れてお申込みください。
                  </p>
                </div>
              </div>
            </li>
            <div className={style.FlowListArrow}>
              <img
                className={style.FlowListArrowImage}
                src="/assets/zennichi/ikkatsu-syukko/FlowListArrowImage.svg"
                alt=""
              />
            </div>
            <li className={style.FlowListItem}>
              <div className={style.FlowListBox}>
                <div
                  className={`${style.FlowListBoxImage} ${style.FlowListBoxImage02}`}
                />
                <div className={style.FlowListBoxText}>
                  <h4 className={style.FlowListBoxTextTitle}>各社と契約</h4>
                  <p className={style.FlowListBoxTextDescription}>
                    自動返信メールを参照しながら、各社に「ラビーネットBBから出稿したい旨」をご連絡いただき、各社と契約してください。
                  </p>
                </div>
              </div>
            </li>
            <div className={style.FlowListArrow}>
              <img
                className={style.FlowListArrowImage}
                src="/assets/zennichi/ikkatsu-syukko/FlowListArrowImage.svg"
                alt=""
              />
            </div>
            <li className={style.FlowListItem}>
              <div className={style.FlowListBox}>
                <div
                  className={`${style.FlowListBoxImage} ${style.FlowListBoxImage03}`}
                />
                <div className={style.FlowListBoxText}>
                  <h4 className={style.FlowListBoxTextTitle}>メールを受領</h4>
                  <p className={style.FlowListBoxTextDescription}>
                    イタンジ側でも設定を行い、出稿準備が完了しましたらメールにて通知いたします。
                  </p>
                </div>
              </div>
            </li>
            <div className={style.FlowListArrow}>
              <img
                className={style.FlowListArrowImage}
                src="/assets/zennichi/ikkatsu-syukko/FlowListArrowImage.svg"
                alt=""
              />
            </div>
            <li className={style.FlowListItem}>
              <div className={style.FlowListBox}>
                <div
                  className={`${style.FlowListBoxImage} ${style.FlowListBoxImage04}`}
                />
                <div className={style.FlowListBoxText}>
                  <h4 className={style.FlowListBoxTextTitle}>ご利用開始</h4>
                  <p className={style.FlowListBoxTextDescription}>
                    ラビーネットBBから出稿が可能になります。
                  </p>
                </div>
              </div>
            </li>
          </ol>
        </div>
      </section>
      <section className="contact-section" id="contact">
        <div className="container-width">
          <div className="contact-container" id="contact">
            <h3 className={style.ContactTitle}>
              <span className={style.TitleBr}>
                <span>お申込み・</span>
                <span>お問い合わせ</span>
              </span>
            </h3>
            <div className="contact-form">
              <ZennichiContactFormIKS />
            </div>
          </div>
        </div>
      </section>
      {/* <ZennichiMobileCTA /> */}
    </>
  );
};

export default ZennichiIkkatsuSyukko;
